/* eslint-disable max-lines-per-function */
import { writeBinaryFile } from "@tauri-apps/api/fs";
import { BaseDirectory, downloadDir, pictureDir } from "@tauri-apps/api/path";
import { FunctionComponent, useEffect, useState } from "react";
import { Box, Button, Grid } from "theme-ui";
import IconBlock from "../buttons/IconBlock";
import InfoModal from "../modals/InfoModal";
import SaveTo from "./SaveTo";
import { Image as ImageIcon } from "@styled-icons/feather/Image";
import { generateFilename } from "../../lib/files";
import { toast } from "react-toastify";
import { save } from "@tauri-apps/api/dialog";
import { toBlob } from "../../lib/image";

type SaveImageProps = {
	readonly settings: { readonly name: string };
	readonly isOpen: boolean;
	readonly image: string;
};

type SaveImageHandlerProps = {
	readonly onClose: () => void;
	readonly download: () => void;
};

const SaveImageModal: FunctionComponent<
	SaveImageProps & SaveImageHandlerProps
> = ({ isOpen, onClose, image, settings }) => {
	return (
		<InfoModal isOpen={isOpen} title="Your Design" onClose={onClose}>
			<Grid
				p={2}
				sx={{
					gridTemplateColumns: ["1fr ", "1fr 1fr", "1fr"],
					gridTemplateRows: [
						"100px .5fr 1fr",
						"1fr ",
						"175px .5fr 1fr",
						"320px .5fr 1fr",
					],
					alignItems: "center",
					justifyContent: "center",
					maxHeight: ["240px", "320px", "480px"],
					maxWidth: ["320px", "640px", "640px"],
				}}
			>
				<img
					src={image}
					alt="Ecogarden Design as PNG"
					style={{
						objectFit: "scale-down",
						maxHeight: "100%",
						maxWidth: "100%",
						justifySelf: "center",
					}}
				/>

				{/*<SaveTo filenameTemplate={generateFilename("png")(settings.name)} />*/}
				<Box sx={{ margin: "0 auto" }}>
					<Button
						onClick={async () => {
							// check if the file exists
							const filePath = await save({
								defaultPath:
									(await pictureDir()) +
									"/" +
									generateFilename("png")(settings.name),
								filters: [
									{
										name: "PNG Image",
										extensions: ["png"],
									},
								],
							});

							if (!filePath) {
								return;
							}

							toBlob(image)
								.then((blob) =>
									blob
										.arrayBuffer()
										.then((buf) => writeBinaryFile(filePath, buf))
										.then(() => {
											toast.success("Saved image", {
												position: toast.POSITION.BOTTOM_LEFT,
											});
											onClose();
										})
								)
								.catch((e) => console.error(e));

							// open up new notification that image was saved
						}}
					>
						<IconBlock icon={ImageIcon} />
						Save Image
					</Button>
				</Box>
			</Grid>
		</InfoModal>
	);
};

export default SaveImageModal;
