import { XSquare } from "@styled-icons/feather/XSquare";
import Tippy from "@tippyjs/react";
import React from "react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "theme-ui";
import { close } from "../../actions/panels";
import slideUp from "../../lib/animations/slideup";
import type { Shape, SHAPES } from "../../shapes";
import IconBlock from "../buttons/IconBlock";
import Header from "../MenuHeader";
import Items from "./Items";

export type Selectable = { readonly onSelection?: (shape: Shape) => void };
export type ShapeItems = {
  readonly items: typeof SHAPES;
};

type OnCancel = {
  readonly onCancel: React.ReactEventHandler;
};

type OnShapeSelection = {
  readonly onShapeSelection: (shape: Shape) => void;
};

const ShapesPanel: React.FunctionComponent<
  OnCancel & OnShapeSelection & ShapeItems
  // eslint-disable-next-line max-lines-per-function
> = ({ onShapeSelection, onCancel, items }) => {
  const dispatch = useDispatch();

  return (
    <Grid
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 3,
      }}
    >
      <Grid
        sx={{
          border: "panel",
          width: 800,
          borderRadius: [0, "primary"],
          boxShadow: "panel",
          bg: "background",

          animation: `${slideUp} ease-out 48ms`,
          pointerEvents: "auto",
          maxWidth: "100vw",
          maxHeight: "calc(var(--vh, 1fh) * 100)",
          gridGap: 2,
          gridTemplateRows: "1fr 12fr auto",

          "@media screen and (max-width: 800px)": {
            gridTemplateRows: "1fr 12fr auto",
          },
        }}
      >
        <Header onCloseRequest={onCancel}>
          Shapes <span style={{ opacity: 0.5 }}>(Click or Drag)</span>
        </Header>
        <Items
          items={items}
          onSelection={onShapeSelection}
          onDragStart={(): void => {
            // Close panel on drag start
            setTimeout((): void => {
              dispatch(close("shapes"));
            }, 30);
          }}
        />
        <Grid
          sx={{ background: "accent-bg", justifyContent: "flex-end" }}
          py={2}
          px={3}
        >
          <Tippy content="Close panel">
            <Button variant="default" onClick={onCancel}>
              <IconBlock icon={XSquare} backIconSx={{ stroke: "accent-bg" }} />
              Close
            </Button>
          </Tippy>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShapesPanel;
