import Tippy from "@tippyjs/react";
import { ThemeUIStyleObject } from "theme-ui";
import IconBlock from "../buttons/IconBlock";
import Button from "../buttons/PrimaryButton";
// import PlusIcon from "../icons/plus.svg";
import { Plus } from "@styled-icons/feather/Plus";

const AddShapeButton: React.FunctionComponent<{
  readonly onActivatePanel: React.EventHandler<React.MouseEvent>;
  readonly sx: ThemeUIStyleObject;
  readonly className: string;
}> = ({ onActivatePanel, sx, className, ...props }) => {
  return (
    <Tippy content="Add a Shape" delay={500}>
      <Button
        p={2}
        variant="leaf"
        aria-label="Add a Shape"
        onClick={onActivatePanel}
        sx={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          ...sx,
        }}
        className={className}
        {...props}
      >
        <IconBlock
          icon={Plus}
          backIconProps={{ stroke: "var(--background-color)" }}
        />
      </Button>
    </Tippy>
  );
};

export default AddShapeButton;
