import React from "react";
import { dashesToSpaces } from "../../lib/string";
import { Shape } from "../../shapes";
import ShapeItem from "./ShapeItem";
import { Selectable } from "./ShapesPanel";

const handleDragStart = (event_: React.DragEvent<HTMLDivElement>): void => {
  const target = event_.target as HTMLDivElement;

  if (!target.dataset.shape) {
    return;
  }

  // DOM elements are only mutable currently
  // eslint-disable-next-line functional/immutable-data, fp/no-mutation
  event_.dataTransfer.dropEffect = "copy";
  event_.dataTransfer.setData("text/plain", target.dataset.shape);
};

export type Draggable = {
  readonly draggable?: boolean;
  readonly onDragStart: React.DragEventHandler;
};

const DraggableItem: React.FunctionComponent<
  {
    readonly item: Shape;
  } & Draggable &
    Selectable
> = ({ item, onDragStart, onSelection, draggable = true }) => {
  return (
    <ShapeItem
      name={dashesToSpaces(item)}
      key={item}
      icon={item}
      data-shape={`group--${item}`}
      onDragStart={(e: React.DragEvent<HTMLDivElement>): void => {
        handleDragStart(e);
        // eslint-disable-next-line babel/no-unused-expressions
        onDragStart && onDragStart(e);
      }}
      draggable={draggable}
      onSelection={onSelection}
    />
  );
};

export default DraggableItem;
