import React from "react";
import styled from "styled-components";
import { ThemeUIStyleObject } from "theme-ui";

const Icon = styled.img`
  pointer-events: none;
  user-select: none;
`;

const ShapeIcon: React.FunctionComponent<{
  readonly alt: string;
  readonly src: string;
  readonly width: number | string;
  readonly height: number | string;
  readonly sx?: ThemeUIStyleObject | undefined;
}> = ({ src, alt, width, height, sx, ...props }) => {
  return (
    <Icon
      src={src}
      alt={alt}
      width={width}
      height={height}
      sx={sx}
      {...props}
    />
  );
};

export default ShapeIcon;
