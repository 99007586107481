/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { fabric } from "fabric";
import React, { useEffect } from "react";
import { connect, useStore } from "react-redux";
import type { Dispatch, Store } from "redux";
import { v4 as uuidv4 } from "uuid";
import { addObject } from "../../actions/objects";
import { close } from "../../actions/panels";
import { getCanvas } from "../../design/canvas";
import type { RootState } from "../../lib/configureStore";
import {
	addShapeToCanvas,
	applyDefaultFilters,
	EcogardenCanvas,
} from "../../lib/fabric";
import { applyLayerFilters } from "../../lib/fabric/layers";
import type {
	EcogardenFabricObjects,
	FabricGroupObject,
} from "../../lib/fabric/objects";
import { createTextbox } from "../../lib/fabric/shapes";
import { loadImageDB, loadImageFromStorage, setupImage } from "../../lib/image";
import log from "../../lib/log";
import { toEcogardenObject } from "../../lib/objects";
import { loadShape } from "../../lib/objects/svg";
import {
	getShapeSVGFile,
	isImage,
	isLine,
	isPath,
	isTextbox,
	Shape,
	SHAPES,
} from "../../shapes";

export const addShape =
	(dispatch: Dispatch) =>
	(canvas: EcogardenCanvas) =>
	(shape: Readonly<EcogardenFabricObjects>): void => {
		addShapeToCanvas(canvas, shape);
		dispatch(addObject(toEcogardenObject(shape)));
	};

/**
 * Drop shape on canvas
 */
// eslint-disable-next-line max-lines-per-function
const drop = async ({
	shape,
	x,
	y,
}: {
	readonly shape: Shape;
	readonly x: number;
	readonly y: number;
}): Promise<FabricGroupObject | undefined> => {
	if (isTextbox(shape)) {
		// add new label object
		const label = createTextbox({ top: y, left: x })("Label");
		return Promise.resolve(label);
	}

	// Droping a line shape should trigger changing modes elsewhere and can be skipped here
	if (isLine(shape)) {
		return Promise.resolve(undefined);
	}

	// When we drop we enter a mode. So don't do anything at this stage beyond switching mode?
	if (isPath(shape)) {
		return Promise.resolve(undefined);
	}

	if (isImage(shape)) {
		return Promise.resolve(undefined);
	}

	return loadShape(getShapeSVGFile(shape), {
		subtype: shape,
		id: uuidv4(),
		left: x,
		top: y,
		originX: "center",
		originY: "center",
	}).then((shape: FabricGroupObject) => {
		log.debug("drop-shape", shape);
		applyDefaultFilters(shape);
		return shape;
	});
};

interface DragFabricEvent extends fabric.IEvent {
	readonly e: DragEvent;
}

/**
 * Handle shape drop events
 */
const onShapeDrop =
	(store: Store) =>
	(canvas: EcogardenCanvas) =>
	(canvasEvent: DragFabricEvent | fabric.IEvent): void => {
		log.debug("onShapeDrop-canvasEvent", canvasEvent);

		const event = canvasEvent.e;
		event.preventDefault();
		event.stopPropagation();

		// Not a DragEvent
		if (!("dataTransfer" in event) || !event.dataTransfer) {
			throw new Error("Invalid data transfer");
		}

		if (event.dataTransfer.files.length > 0) {
			// handle uploading files

			const file = event.dataTransfer.files[0];

			if (!file) {
				// invalid file?
				return;
			}

			// load image into indexedDB
			loadImageDB().then((db) => {
				const id = uuidv4();
				if (
					![
						"image/png",
						"image/gif",
						"image/webp",
						"image/avif",
						"image/jpeg",
					].includes(file.type)
				) {
					log.debug("Dropped an invalid image", file.type);
					return;
				}
				db.put("images", file, id).then(() => {
					// eslint-disable-next-line max-nested-callbacks
					loadImageFromStorage(db)({ id }).then((img) => {
						if (!img) {
							return;
						}

						const { x, y } = canvas.getPointer(event);

						img.set("top", y);
						img.set("left", x);

						setupImage(img);

						canvas.add(img);

						// canvas.centerObject(img);
						// img.setCoords()
						canvas.setActiveObject(img);
						canvas.requestRenderAll();
						store.dispatch(addObject(toEcogardenObject(img)));
						applyLayerFilters(canvas)(store.getState().layers);
					});
				});
			});
			return;
		}

		const data = event.dataTransfer.getData("text/plain");
		const [type, subtypeString] = data.split("--");
		const { x, y } = canvas.getPointer(event);

		log.debug("onShapeDrop", type, subtypeString);

		// Validate subtype in SHAPES
		if (!SHAPES.includes(subtypeString as Shape)) {
			throw new Error(`Invalid subtype in drop. ${subtypeString}`);
		}

		// Typecast unknown string as Shape
		const shape = subtypeString as Shape;

		log.debug("onShapeDrop-viewportTransform", canvas.viewportTransform);
		log.debug("onShapeDrop", x, y);

		drop({ shape, x, y }).then((fabricObject) => {
			// If we get an invalid object, skip adding it and close the shapes panel
			if (!fabricObject) {
				store.dispatch(close("shapes"));
				return;
			}

			addShape(store.dispatch)(canvas)(fabricObject);
			canvas.setActiveObject(fabricObject);

			store.dispatch(close("shapes"));
		});
	};

/**
 * Canvas to capture drop events to handle adding shapes to the fabric Canvas
 */
const DropItemCanvas: React.FunctionComponent<{
	readonly canvas?: EcogardenCanvas;
}> = ({ canvas }) => {
	const store = useStore<RootState>();

	useEffect(() => {
		if (!canvas) {
			return;
		}

		const handleDrop = onShapeDrop(store)(canvas);

		canvas.on("drop", handleDrop);

		return function cleanup(): void {
			canvas.off("drop", handleDrop);
		};
	});

	return <></>;
};

export default connect((state: RootState) => ({
	canvas: getCanvas(state.canvas) as EcogardenCanvas | undefined,
}))(DropItemCanvas);
