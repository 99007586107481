import { Upload } from "@styled-icons/feather/Upload";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Box, Button, Flex, Grid, Label } from "theme-ui";
import { v4 as uuidv4 } from "uuid";
import { addObject } from "../../actions/objects";
import { getCanvas } from "../../design/canvas";
import slideUp from "../../lib/animations/slideup";
import { useAppSelector } from "../../lib/configureStore";
import { EcogardenCanvas } from "../../lib/fabric";
import { applyLayerFilters } from "../../lib/fabric/layers";
import { loadImageDB, loadImageFromStorage, setupImage } from "../../lib/image";
import log from "../../lib/log";
import { toEcogardenObject } from "../../lib/objects";
import IconBlock from "../buttons/IconBlock";

const addImage =
  // eslint-disable-next-line max-lines-per-function


    (canvas: EcogardenCanvas) =>
    // eslint-disable-next-line max-lines-per-function
    (file: File): Promise<fabric.Image> => {
      if (
        ![
          "image/png",
          "image/gif",
          "image/webp",
          "image/avif",
          "image/jpeg",
        ].includes(file.type)
      ) {
        log.warning("Dropped an invalid image", file.type);
        return Promise.reject("Invalid image added.");
      }
      const id = uuidv4();

      return loadImageDB().then((db) => {
        return db.put("images", file, id).then(() => {
          // eslint-disable-next-line max-nested-callbacks
          return loadImageFromStorage(db)({ id }).then((img) => {
            if (!img) {
              return Promise.reject(
                `Could not load image (${id.substring(0, 8)}) from storage`
              );
            }
            setupImage(img);
            if (!img.top && !img.left) {
              const { x, y } = canvas.getVpCenter();
              canvas.add(img);
              img.set("top", y);
              img.set("left", x);
            }
            canvas.add(img);
            canvas.requestRenderAll();
            // canvas.centerObject(img);
            // img.setCoords()
            canvas.setActiveObject(img);
            canvas.requestRenderAll();

            return img;
          });
        });
      });
    };

const ImageTool: React.FunctionComponent<{
  readonly onFinish: () => void;
  readonly onCancel: () => void;
  // eslint-disable-next-line max-lines-per-function
}> = ({ onFinish, onCancel }) => {
  const [canvas, layers] = useAppSelector((state) => {
    return [
      getCanvas(state.canvas) as EcogardenCanvas | undefined,
      state.layers,
    ];
  });
  const dispatch = useDispatch();

  return (
    <Grid
      sx={{
        gridTemplateColumns: "1fr auto",
        padding: 2,
        backgroundColor: "accent-bg",
        px: [3, 2],
        py: 2,
        alignItems: "center",
        justifyItems: "center",
        width: ["100vw", "22em"],
        maxWidth: ["calc(var(--vh, 1vh) * 100)", "75vw"],
        gridGap: 4,
        fontSize: [0, 1, 2],
        borderRadius: "panelBottom",
        animation: `${slideUp} 96ms ease-in-out`,
      }}
    >
      <Label
        htmlFor="load-image"
        sx={{
          pointerEvents: "all",
          display: "grid",
          backgroundColor: "background",
          border: "panel",
          gridTemplateColumns: "auto 6fr",
          alignItems: "center",
          padding: 2,
          fontSize: [0, 1, 2],
          borderStyle: "dashed",
          borderRadius: "primary",
          justifyItems: "center",
          cursor: "pointer",
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        // eslint-disable-next-line max-lines-per-function, complexity
        onDrop={(e) => {
          e.preventDefault();

          if (!canvas) {
            return;
          }

          if (!e.dataTransfer.files || !e.dataTransfer.files[0]) {
            return;
          }

          const file = e.dataTransfer.files[0];

          if (!file) {
            // invalid file?
          }

          addImage(canvas)(file)
            .then((img) => {
              dispatch(addObject(toEcogardenObject(img)));
              applyLayerFilters(canvas)(layers);
              onFinish();
            })
            .catch((e) => {
              console.error("error loading image", e);
            });
        }}
      >
        <IconBlock icon={Upload} />
        <Box>Upload a site plan image.</Box>
      </Label>
      <input
        type="file"
        name="image"
        id="load-image"
        // eslint-disable-next-line max-lines-per-function, complexity
        onChange={(e) => {
          if (!canvas) {
            return;
          }

          if (!e.target.files || !e.target.files[0]) {
            return;
          }

          const file = e.target.files[0];

          if (!file) {
            // invalid file?
          }

          addImage(canvas)(file)
            .then((img) => {
              dispatch(addObject(toEcogardenObject(img)));
              applyLayerFilters(canvas)(layers);
              onFinish();
            })
            .catch((e) => {
              console.error("error loading image", e);
            });
        }}
        sx={{ display: "none" }}
      />
      <Box sx={{ justifySelf: "flex-end" }}>
        <Button variant="default" onClick={onCancel}>
          Done
        </Button>
      </Box>
    </Grid>
  );
};

export default ImageTool;
