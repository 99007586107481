/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import React from "react";
import { connect, useDispatch } from "react-redux";
import type { Dispatch } from "redux";
import { Box, Card, Flex } from "theme-ui";
import { v4 } from "uuid";
import { close } from "../../actions/panels";
import { getCanvas } from "../../design/canvas";
import type { RootState } from "../../lib/configureStore";
import { useAppSelector } from "../../lib/configureStore";
import { applyDefaultFilters, EcogardenCanvas } from "../../lib/fabric";
import type { FabricGroupObject } from "../../lib/fabric/objects";
import { createTextbox } from "../../lib/fabric/shapes";
import log from "../../lib/log";
import { loadShape } from "../../lib/objects/svg";
import { capitalize } from "../../lib/string";
import {
	getMainAssetFile,
	getShapeSVGFile,
	isCustomShape,
	isImage,
	isLineTool,
	isPath,
	isTextbox,
	LayersState,
	Shape,
} from "../../shapes";
import { addShape } from "./DropItemCanvas";
import ShapeIcon from "./ShapeIcon";
import SitePlanDrawIcon from "../../../svg/objects/site-plan-draw.svg";
import LineIcon from "../../../svg/objects/line.svg";
import Textbox from "../../../svg/textbox.svg";
import LabelDrawIcon from "../../../svg/objects/label-draw.svg";
import { Image } from "@styled-icons/feather/Image";

/**
 * TODO: Unify with how dropping items works instead of 2 implementations
 */
const handleAddItemToCanvas =
	(dispatch: Dispatch) =>
	(canvas?: EcogardenCanvas) =>
	(layers: LayersState) =>
	(name: string, icon: Shape) =>
	// eslint-disable-next-line complexity
	(e: React.MouseEvent) => {
		if (!canvas) {
			console.error("could not add to canvas due to invalid canvas");
			return;
		}

		if (isCustomShape(icon)) {
			// start polygon
			dispatch(close("shapes"));
			return;
		}

		if (isPath(icon)) {
			// start path mode
			dispatch(close("shapes"));
			return;
		}

		if (isImage(icon)) {
			// start path mode
			dispatch(close("shapes"));
			return;
		}

		if (isLineTool(icon)) {
			// start line
			dispatch(close("shapes"));
			return;
		}

		const { x, y } = canvas.getVpCenter();

		// add label
		if (isTextbox(icon)) {
			// add new label object
			const label = createTextbox({ top: y, left: x })("Label");
			addShape(dispatch)(canvas)(label);
			dispatch(close("shapes"));
			return;
		}

		return loadShape(getShapeSVGFile(icon), {
			type: "group",
			subtype: icon,
			id: v4(),
			left: x,
			top: y,
		})
			.then((shape: FabricGroupObject) => {
				log.debug("drop-shape", shape);
				applyDefaultFilters(shape);
				// canvas.centerObject(shape)

				shape.setCoords();

				// Set as active object, but force to front until they deselect it
				canvas.setActiveObject(shape);

				// const afterCleared = (): void => {
				//   canvas._objects.sort(sortCanvasObjects);
				//   dispatch(sortObjects());

				//   applyLayerFilters(canvas)(layers);
				//   canvas.off("before:selection:cleared", afterCleared);
				// };

				// canvas.on("before:selection:cleared", afterCleared);

				canvas.requestRenderAll();

				return shape;
			})
			.then((shape) => {
				addShape(dispatch)(canvas)(shape);

				dispatch(close("shapes"));
			});
	};

const ShapeItem: React.FunctionComponent<{
	/**
	 * handle drag events on item cards into DropitemCanvas
	 */
	readonly onDragStart?: React.DragEventHandler;

	/**
	 * handle click events on item cards
	 */
	readonly onClick?: React.MouseEventHandler;

	/**
	 * event when an item is selected
	 */
	readonly onSelection?: (shape: Shape) => void;

	/**
	 * @default true
	 */
	readonly draggable?: boolean;

	/**
	 * name of the shape
	 */
	readonly name: string;

	/**
	 * icon key for the shape
	 * Example: deciduous-tree
	 */
	readonly icon: Shape;
	readonly canvas?: EcogardenCanvas;
	readonly disaptch?: Dispatch;
	// eslint-disable-next-line complexity
}> = ({
	name,
	icon,
	onDragStart,
	onClick,
	onSelection,
	draggable = true,
	// canvas,
	// dispatch,
	...properties
}) => {
	const dispatch = useDispatch();
	const canvas = useAppSelector(
		(state) => getCanvas(state.canvas) as EcogardenCanvas
	);
	const { layers, freedrawTool, lineTool, imageShape } = useAppSelector(
		(state) => ({
			layers: state.layers,
			canvas: state.canvas,
			freedrawTool: state.features["freedraw-tool"] ?? false,
			lineTool: state.features["line-tool"],
			imageShape: state.features["image-shape"],
		})
	);

	if (name === "path" && !freedrawTool) {
		return <></>;
	}

	if (name === "site-plan-draw" && !freedrawTool) {
		return <></>;
	}

	if (name === "line" && !lineTool) {
		return <></>;
	}

	if (name === "image" && !imageShape) {
		return <></>;
	}

	return (
		<Card
			role="option"
			bg="background"
			sx={{
				cursor: "pointer",
				fontSize: [0, 0, 1],

				"&:hover,&:focus": {
					boxShadow: "glow",
					bg: "accent-bg",
				},
			}}
			onDragStart={(event: React.DragEvent) => {
				// Dragging sets the selection.
				// On selection we remove the panel and start the "placing/designing" tools
				// Dropping the shape will start the polygon tool or place the item on the canvas
				onSelection && onSelection(icon);
				onDragStart && onDragStart(event);
			}}
			onClick={(event) => {
				onClick && onClick(event);
				onSelection && onSelection(icon);
				handleAddItemToCanvas(dispatch)(canvas)(layers)(name, icon)(event);
			}}
			draggable={draggable}
			title={capitalize(name)}
			{...properties}
		>
			<Flex
				p={2}
				sx={{
					justifyContent: "center",
					pointerEvents: "none",
				}}
			>
				{icon === "site-plan-draw" ? (
					<SitePlanDrawIcon width={48} height={48} strokeWidth={2} />
				) : icon === "label" ? (
					<Textbox width={48} height={48} strokeWidth={1} />
				) : icon === "line" ? (
					<LineIcon width={48} height={48} strokeWidth={1} />
				) : icon === "image" ? (
					<Image width={48} height={48} strokeWidth={1} />
				) : icon === "label-draw" ? (
					<LabelDrawIcon width={48} height={48} strokeWidth={1} />
				) : (
					<ShapeIcon
						src={getMainAssetFile(icon)}
						alt={`${capitalize(name)} Icon`}
						width={48}
						height={48}
						sx={{
							"@media(max-height: 420px)": {
								width: "32px",
								height: "32px",
							},
						}}
					/>
				)}
			</Flex>
			<Box sx={{ lineHeight: 1, textAlign: "center", pointerEvents: "none" }}>
				{capitalize(name)}
			</Box>
		</Card>
	);
};

// export default connect(({ canvas }: RootState) => ({
//   canvas: getCanvas(canvas) as EcogardenCanvas,
// }))(ShapeItem);
export default ShapeItem;
