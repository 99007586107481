/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { ExtendedKeyboardEvent } from "mousetrap";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { Box, Grid } from "theme-ui";
import { deleteObjects } from "../../actions/objects";
import { close, open } from "../../actions/panels";
import { getCanvas } from "../../design/canvas";
import slideDown from "../../lib/animations/slidedown";
import slideUp from "../../lib/animations/slideup";
import type { RootState } from "../../lib/configureStore";
import { SelectableCanvas } from "../../lib/controls/mousePan";
import useMousetrap from "../../lib/controls/mousetrap";
import { EcogardenCanvas, setFadeOpacity } from "../../lib/fabric";
import {
  fillOpacityFilter,
  layerLockVisibleFilter,
} from "../../lib/fabric/layers";
import {
  EcogardenFabricObject,
  removeActiveObjects,
} from "../../lib/fabric/objects";
// Shapes
import {
  isCustomShape,
  isImage,
  isLineTool,
  isPath,
  LayersState,
  Shape,
  SHAPES,
} from "../../shapes";
import FreedrawTool from "../tools/FreedrawTool";
import AddShapeButton from "./AddShapeButton";
import DropItemCanvas from "./DropItemCanvas";
import ImageTool from "./Image";
import LineShape from "./LineShape";
import PolygonShape from "./PolygonShape";
import ShapesPanel from "./ShapesPanel";

type CanvasMousetrapEvent = (
  canvas?: EcogardenCanvas
) => (dispatch: Dispatch) => (e: ExtendedKeyboardEvent) => void;

const deleteShape: CanvasMousetrapEvent =
  (canvas) =>
  (dispatch) =>
  (e): void => {
    if (!canvas) {
      return;
    }

    // FIXME: add confirmation
    e.preventDefault();
    e.stopPropagation();

    removeActiveObjects(canvas).then((ids) => {
      canvas.discardActiveObject(e);
      canvas.requestRenderAll();

      dispatch(
        deleteObjects(ids.filter((id) => id !== undefined) as readonly string[])
      );
    });
  };

/**
 * Restoring original state of objects on canvas
 * make canvas selectable and able to make a selection
 */
const restoreCanvas =
  (layers: LayersState) => (canvas: SelectableCanvas<Event>) => {
    // Full opacity back in for all objects
    canvas.forEachObject((object) => {
      layerLockVisibleFilter(layers)(object as EcogardenFabricObject);
      fillOpacityFilter(layers)(object as EcogardenFabricObject);
    });

    // set shapes as selectable again
    canvas.selection = true;
    canvas.selectable = true;

    canvas.requestRenderAll();
  };

/**
 * Disable selectability and selection on the canvas and all objects and fade out all objects
 */
const disableInteractivityOnCanvas = (canvas: SelectableCanvas<Event>) => {
  // Fade out all objects
  canvas.forEachObject((object) => {
    object.selectable = false;
    setFadeOpacity(object);
  });

  // set shapes unselectable
  canvas.selection = false;
  canvas.selectable = false;

  canvas.requestRenderAll();
};

const ShapesContainer: React.FunctionComponent<{
  readonly onCreation?: () => void;
  readonly onCreationFinish?: () => void;
}> = ({ onCreation, onCreationFinish }) => {
  const [isUsingPolygonTool, setIsUsingPolygonTool] = useState(false);
  const [isUsingFreedrawTool, setIsUsingFreedrawTool] = useState(false);
  const [isUsingLineTool, setIsUsingLineTool] = useState(false);
  const [isUsingImageTool, setIsUsingImageTool] = useState(false);
  const [shapeSelected, setShapeSelected] = useState<Shape | undefined>(
    undefined
  );
  const { canvas, isPanelOpen, layers } = useSelector((state: RootState) => ({
    canvas: getCanvas(state.canvas) as SelectableCanvas<Event> &
      EcogardenCanvas,
    isPanelOpen: state.panels.shapes,
    layers: state.layers,
  }));
  const dispatch = useDispatch();

  const reset = (): void => {
    dispatch(close("shapes"));
    setIsUsingPolygonTool(false);
    setIsUsingFreedrawTool(false);
    setIsUsingLineTool(false);
    setIsUsingImageTool(false);
  };

  const handleActivatePanel: React.EventHandler<React.MouseEvent> = () => {
    dispatch(open("shapes"));
    // activatePanel(canvas)(e.e);
    // changeState(true);
  };

  useMousetrap({
    esc: () => {
      dispatch(close("shapes"));
    },
    del: deleteShape(canvas)(dispatch),
  });

  // useEffect(() => {
  //   if (!canvas) {
  //     return;
  //   }

  //   const handleMouseDoubleClick = (e: fabric.IEvent) => {
  //     const obj = e.target;

  //     if (obj?.type === "line") {
  //       // enter edit mode for line
  //       setIsUsingLineTool(true);
  //       setIsUsingPolygonTool(false);

  //       applyControls(obj as EcogardenFabricLine);

  //       // if (obj.selectable === false) {
  //       //   obj.selectable = true;
  //       //   obj.perPixelTargetFind = false;
  //       //   obj.on("deselected", () => {
  //       //     // handle restore selelectablity
  //       //     obj.selectable = false;
  //       //     obj.perPixelTargetFind = true;
  //       //     // canvas.discardActiveObject()
  //       //   });
  //       // }

  //       canvas.setActiveObject(obj);

  //       // apply line control

  //       function deselected(e: fabric.IEvent) {
  //         console.log("deselected", e);
  //         setIsUsingLineTool(false);
  //         obj?.off("deselected", deselected);
  //       }

  //       obj.on("deselected", deselected);
  //       canvas.renderAll();
  //     }
  //   };

  //   canvas.on("mouse:dblclick", handleMouseDoubleClick);

  //   return function cleanup() {
  //     canvas.off("mouse:dblclick", handleMouseDoubleClick);
  //   };
  // }, [canvas]);

  if (!canvas) {
    return <></>;
  }

  return (
    <Grid
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-end",
        pointerEvents: "none",
        gridArea: "shape",
        zIndex: 3,
      }}
    >
      <DropItemCanvas />
      {isPanelOpen && !isUsingPolygonTool && !isUsingFreedrawTool && (
        <ShapesPanel
          onShapeSelection={(shape: Shape) => {
            canvas.discardActiveObject();
            canvas.requestRenderAll();

            if (isCustomShape(shape)) {
              setIsUsingPolygonTool(true);
              setShapeSelected(shape);
              onCreation && onCreation();
            }

            if (isImage(shape)) {
              setIsUsingImageTool(true);
              setShapeSelected(shape);
              onCreation && onCreation();
            }

            if (isPath(shape)) {
              setIsUsingFreedrawTool(true);
              setShapeSelected(shape);
              disableInteractivityOnCanvas(canvas);
              onCreation && onCreation();
            }
            if (isLineTool(shape)) {
              setIsUsingLineTool(true);
              setShapeSelected(shape);
              onCreation && onCreation();

              disableInteractivityOnCanvas(canvas);
            }
          }}
          onCancel={reset}
          items={SHAPES}
        />
      )}
      {isUsingPolygonTool && shapeSelected && (
        <PolygonShape
          onFinish={() => {
            // TODO: finsh polygon shape
            setIsUsingPolygonTool(false);
            onCreationFinish && onCreationFinish();
            restoreCanvas(layers)(canvas);
          }}
          onCancel={() => {
            setIsUsingPolygonTool(false);
            onCreationFinish && onCreationFinish();
            dispatch(close("shapes"));
          }}
          shape={shapeSelected}
        />
      )}

      {isUsingFreedrawTool && (
        <FreedrawTool
          type={shapeSelected ?? "label-draw"}
          onCancel={() => {
            setIsUsingFreedrawTool(false);
            onCreationFinish && onCreationFinish();
            restoreCanvas(layers)(canvas);
          }}
          onFinish={() => {
            setIsUsingFreedrawTool(false);
            onCreationFinish && onCreationFinish();
            restoreCanvas(layers)(canvas);
          }}
        />
      )}
      {isUsingLineTool && shapeSelected && (
        <LineShape
          onFinish={() => {
            console.log("onfinish");
            setIsUsingLineTool(false);
            onCreationFinish && onCreationFinish();
            restoreCanvas(layers)(canvas);
          }}
          onCancel={() => {
            console.log("oncacnel");
            setIsUsingLineTool(false);
            onCreationFinish && onCreationFinish();
            restoreCanvas(layers)(canvas);
          }}
        />
      )}

      {isUsingImageTool && (
        <ImageTool
          onFinish={() => {
            setIsUsingImageTool(false);
            onCreationFinish && onCreationFinish();
            restoreCanvas(layers)(canvas);
          }}
          onCancel={() => {
            setIsUsingImageTool(false);
            onCreationFinish && onCreationFinish();
            restoreCanvas(layers)(canvas);
          }}
        />
      )}

      {!isPanelOpen &&
        !isUsingPolygonTool &&
        !isUsingLineTool &&
        !isUsingImageTool &&
        !isUsingFreedrawTool && (
          <Box p={2} data-x="one" sx={{ alignSelf: "flex-end" }}>
            <AddShapeButton
              sx={{
                width: ["44px", "64px"],
                height: ["44px", "64px"],

                animation: [
                  `${slideUp} ease-in 96ms`,
                  `${slideDown} ease-in 96ms`,
                ],
                pointerEvents: "all",

                "@media screen and (max-height: 360px)": {
                  width: 44,
                  height: 44,
                },
              }}
              onActivatePanel={handleActivatePanel}
              className="add-shape-button"
            />
          </Box>
        )}
    </Grid>
  );
};

export default ShapesContainer;
