/* eslint-disable max-lines */
import { Check } from "@styled-icons/feather/Check";
import { Slash } from "@styled-icons/feather/Slash";
import { fabric } from "fabric";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Box, Button, Grid } from "theme-ui";
import { v4 } from "uuid";
import { getCanvas } from "../../design/canvas";
import slideDown from "../../lib/animations/slidedown";
import slideUp from "../../lib/animations/slideup";
import type { RootState } from "../../lib/configureStore";
import {
  applyControls as applyPolygonControls,
  setIsControlDragging,
  __isControlDragging,
} from "../../lib/controls/fabric/polygon";
import { EcogardenFabricPolygon } from "../../lib/fabric/objects";
import {
  addPolygonPoint,
  finishEditingPolygon,
} from "../../lib/fabric/polygon";
import { createPolygon } from "../../lib/fabric/shapes";
import log from "../../lib/log";
import { Shape } from "../../shapes";
import IconBlock from "../buttons/IconBlock";

// stages
//
// start polygon on drag or click
// - drag starts polygon at a point.
// - click starts the polygon tool

type Properties = {
  readonly onFinish?: MouseEventHandler;
  readonly onCancel?: MouseEventHandler;
  readonly shape: string;
  readonly canvas?: fabric.Canvas;
  readonly panning: boolean;
  readonly dispatch: Dispatch;
};

// eslint-disable-next-line max-lines-per-function
const PolygonShape: React.FunctionComponent<Properties> = ({
  onFinish,
  onCancel,
  shape,
  canvas,
  panning,
  dispatch,
}) => {
  const [polygon, setPolygon] = useState<EcogardenFabricPolygon | undefined>(
    () => {
      if (!canvas) {
        return;
      }
      setIsControlDragging(false);

      const p = createPolygon({
        id: v4(),
        subtype: shape as Shape,
        objectCaching: false,
        perPixelTargetFind: true,
        strokeWidth: 4,
        stroke: "gold",
        transparentCorners: true,
      })([]);

      canvas.add(p);
      canvas.setActiveObject(p);
      applyPolygonControls(p);
      canvas.requestRenderAll();

      p.on("deselected", () => {
        setIsControlDragging(false);
        // p.set("objectCaching", true);
      });

      return p;
    }
  );

  const handleFinish: MouseEventHandler = (e) => {
    if (!canvas) {
      return;
    }

    if (polygon) {
      finishEditingPolygon(canvas)(polygon)(dispatch);
    }

    canvas.requestRenderAll();

    onFinish && onFinish(e);
  };

  const handleCancel: MouseEventHandler = (e) => {
    if (!canvas) {
      return;
    }

    if (polygon) {
      canvas.remove(polygon);
      setPolygon(undefined);
    }

    canvas.requestRenderAll();

    onCancel && onCancel(e);
  };

  // eslint-disable-next-line max-lines-per-function
  useEffect(() => {
    if (!canvas) {
      return;
    }

    let lastPoint = polygon?.points?.length ?? 0;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    const handleMouseDown = (e: fabric.IEvent): void => {
      //
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    const handleMouseMove =
      // eslint-disable-next-line max-lines-per-function


        (polygon: EcogardenFabricPolygon | undefined) =>
        // eslint-disable-next-line max-lines-per-function
        (e: fabric.IEvent): void => {
          if (!polygon) {
            return;
          }
          // if (polygon?.points && polygon?.points.length <= lastPoint) {
          //   console.log("last point", lastPoint, polygon?.points?.length);
          //   // // make new point
          //   // addPolygonPoint(canvas)(polygon)(canvas.getPointer(e.e));
          //   // applyPolygonControls(polygon);
          //   // canvas.requestRenderAll();
          // } else {
          //   if (!polygon.points) {
          //     return;
          //   }

          //   if (polygon.points.length <= 0) {
          //     return;
          //   }

          //   const cursor = canvas.getPointer(e.e);
          //   const points = [...polygon?.points];
          //   points[lastPoint - 1] = cursor;

          // points.forEach(p => console.log(p))

          //   polygon.set("points", points);
          //   polygon._setPositionDimensions({});

          //   polygon.set("dirty", true);
          //   polygon.setCoords();
          //   canvas.requestRenderAll();
          // }
        };

    // eslint-disable-next-line complexity
    const handleMouseUp = (e: fabric.IEvent): void => {
      if (!canvas || !polygon) {
        return;
      }

      // Skip handling click if panning
      if (panning) {
        return;
      }

      // On mouse up, we need to detect if we were using the control
      if (__isControlDragging) {
        // Reset control dragging state
        setIsControlDragging(false);
        return;
      }

      addPolygonPoint(canvas)(polygon)(canvas.getPointer(e.e, false));
      polygon.hasControls = true;
      applyPolygonControls(polygon);

      polygon._projectStrokeOnPoints();
      canvas.setActiveObject(polygon);
      canvas.requestRenderAll();

      log.debug("last point", lastPoint, polygon?.points?.length);
      lastPoint = polygon?.points?.length ?? 0;
    };

    const handleMouseMovePolygon = handleMouseMove(polygon);

    canvas.on("mouse:down", handleMouseDown);
    canvas.on("mouse:up", handleMouseUp);
    canvas.on("mouse:move", handleMouseMovePolygon);

    return function cleanup() {
      canvas.off("mouse:down", handleMouseDown);
      canvas.off("mouse:up", handleMouseUp);
      canvas.off("mouse:move", handleMouseMovePolygon);
    };
  }, [shape, polygon, canvas, panning]);

  return (
    <Grid
      sx={{
        justifyContent: "center",
        animation: [`${slideUp} 44ms ease-out`, `${slideDown} 44ms ease-out`],
        position: ["absolute", "initial"],
        gridGap: "2px",
        left: 0,
        width: ["100vw", "50vw"],
        maxWidth: ["100%", "20em"],
        backgroundColor: "accent-bg",
        borderRadius: "panelBottom",
      }}
    >
      <Box
        sx={{
          p: [1, 2],
          backgroundColor: "background",
          textShadow: "heading",
          fontSize: 0,
          lineHeight: 1.3,
          opacity: 0.85,
          borderRadius: "panelBottom",
          width: ["100vw", "100%"],
          textAlign: "center",
        }}
      >
        Click/Tap on the canvas to set polygon points for your {shape}.{" "}
        {polygon && polygon?.points?.length && polygon?.points?.length < 2
          ? "Need a minimum of 2 points."
          : ""}
      </Box>
      <Grid
        sx={{
          justifyContent: "center",
          gridAutoFlow: "column",
          fontSize: [1, 1],
          gridGap: [2, 3],
          padding: 1,
        }}
      >
        <Button
          variant="default"
          sx={{ paddingX: 4, paddingY: 2 }}
          onClick={handleCancel}
        >
          <IconBlock icon={Slash} />
          {/* <Slash width={24} height={24} strokeWidth={2} /> Cancel */}
          Cancel
        </Button>

        <Button
          sx={{
            paddingX: 4,
            paddingY: 2,
            borderRadius: "primary",
          }}
          onClick={handleFinish}
        >
          <IconBlock icon={Check} />
          {/* <Check width={24} heiclht={24} strokeWidth={2} /> Finish */}
          Finish
        </Button>
      </Grid>
    </Grid>
  );
};

export default connect(({ canvas, pan }: RootState) => ({
  canvas: getCanvas(canvas),
  panning: pan,
}))(PolygonShape);
