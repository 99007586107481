import React from "react";
import { Grid } from "theme-ui";
import type { Shape, SHAPES } from "../../shapes";
import DraggableItem, { Draggable } from "./DraggableItem";

const rotateWheelScroll = (e: React.WheelEvent<HTMLDivElement>): void => {
  if (!e.target) {
    return;
  }

  const eventTarget = e.target as HTMLDivElement;

  const target =
    eventTarget.tagName === "DIV"
      ? (eventTarget.parentNode as HTMLDivElement)
      : (eventTarget as HTMLDivElement);

  if (!target) {
    return;
  }

  if (e.deltaY < 0) {
    target.scrollLeft -= e.deltaY * 10;
  } else {
    target.scrollLeft += e.deltaY * 10;
  }
};

export type Selectable = { readonly onSelection?: (shape: Shape) => void };

const Items: React.FunctionComponent<
  {
    readonly items: typeof SHAPES;
    readonly className?: string;
  } & Selectable &
    Draggable
> = ({ className, items, onDragStart, onSelection, ...properties }) => {
  return (
    <Grid
      as="ul"
      className={className}
      onWheel={rotateWheelScroll}
      id="items"
      aria-labelledby="shapes-panel-title"
      role="listbox"
      p={2}
      gap={2}
      sx={{
        gridTemplateColumns: "repeat(auto-fit, minmax(96px, 1fr))",
        justifyContent: "center",
        overflowY: "auto",
        maxHeight: "85vh",
      }}
      {...properties}
    >
      {items.map((item) => (
        <DraggableItem
          item={item}
          key={item}
          onSelection={onSelection}
          onDragStart={onDragStart}
        />
      ))}
    </Grid>
  );
};

export default Items;
