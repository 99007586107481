/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { fabric } from "fabric";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Flex, Grid, Input, Label, Radio } from "theme-ui";
import { addObject } from "../../actions/objects";
import { getCanvas } from "../../design/canvas";
import { RootState } from "../../lib/configureStore";
import { EcogardenCanvas } from "../../lib/fabric";
import { createLine } from "../../lib/fabric/objects";
import { EcogardenLine, toEcogardenObject } from "../../lib/objects";
import { sortCanvasObjects } from "../../shapes";

import { v4 as uuidv4 } from "uuid";
import { applyControls as applyLineControls } from "../../lib/controls/fabric/line";
import { applyLayerFilters } from "../../lib/fabric/layers";
import { useHotkeys } from "react-hotkeys-hook";
import { capitalize } from "../../lib/string";
import slideUp from "../../lib/animations/slideup";
import IconBlock from "../buttons/IconBlock";
import { Check } from "@styled-icons/feather/Check";
import StrokeSizes from "./StrokeSizes";
import LineIcon from "../../../svg/objects/line.svg";
import log from "../../lib/log";

type Point = { readonly x: number; readonly y: number };

type Sizes = "small" | "medium" | "large";

const sizeToNumber = (size: Sizes): number => {
	if (size === "small") {
		return 2;
	}

	if (size === "medium") {
		return 4;
	}

	if (size === "large") {
		return 8;
	}

	return 2;
};

const LineShape: React.FunctionComponent<{
	readonly onFinish: () => void;
	readonly onCancel: () => void;
	readonly line?: EcogardenLine;
}> = ({ onFinish, onCancel, line }) => {
	const [color, setColor] = useState<string | undefined>(undefined);
	const [size, setSize] = useState<Sizes>("medium");
	const [showSizes, setShowSizes] = useState<boolean>(false);
	// const [line, setLine] = useState<fabric.Line | undefined>(undefined)
	const { canvas, layers } = useSelector((state: RootState) => {
		return {
			canvas: getCanvas(state.canvas) as EcogardenCanvas | undefined,
			layers: state.layers,
		};
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if (!canvas) {
			return;
		}

		let fLine: fabric.Line | undefined = undefined;

		const handleMouseDown = (e: fabric.IEvent) => {
			// click to start point,
			//  - new points can be created
			//    - 1 point (2 point line)
			//    - x point (keep adding points until you click the last point)
			// drag to create line
			//  - mouse/touch down start point
			//    - is dragging if point moved to is > minimum distance (25-44?)
			//    - once dragging is triggered needs to remained triggered until mouse/touch up
			// line should be created and moved as the cursor is moved
			//
			const downPoint = canvas.getPointer(e.e);

			// we have a line already, are we making a second point?
			if (fLine && fLine.x1 && fLine.y1) {
				fLine.set("x2", downPoint.x);
				fLine.set("y2", downPoint.y);

				dispatch(addObject(toEcogardenObject(fLine)));
				fLine = undefined;

				canvas._objects.sort(sortCanvasObjects);
				canvas.requestRenderAll();
				return;
			}

			// now we need add the line to the canvas
			// using start point as start and end points
			const fabricLine = createLine({
				id: uuidv4(),
				stroke: color ?? "black",
				strokeWidth: sizeToNumber(size),
			})([downPoint.x, downPoint.y, downPoint.x, downPoint.y]);

			canvas.add(fabricLine);

			fabricLine.hasBorders = true;
			fabricLine.perPixelTargetFind = false;
			applyLineControls(fabricLine);

			applyLayerFilters(canvas)(layers);
			canvas.requestRenderAll();

			// setLine(fabricLine);
			fLine = fabricLine;

			canvas.on("mouse:move", handleMouseMove);
		};

		const handleMouseMove = (e: fabric.IEvent) => {
			if (!fLine) {
				log.warning("move, no line set");
				return;
			}

			const currentPoint = canvas.getPointer(e.e);
			fLine.set("x2", currentPoint.x);
			fLine.set("y2", currentPoint.y);

			fLine.setCoords();
			canvas.renderAll();
		};

		const handleMouseUp = (e: fabric.IEvent) => {
			if (!fLine) {
				// log.warning("up, no line set");
				return;
			}

			fLine.hasBorders = false;
			fLine.perPixelTargetFind = true;
			const currentPoint = canvas.getPointer(e.e);

			// detect if the line is of minimum distance (to remove misclicks)
			//
			const distance =
				(a: Point) =>
				(b: Point): number => {
					return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
				};

			if (
				fLine.x1 &&
				fLine.y1 &&
				distance({ x: fLine.x1, y: fLine.y1 })(currentPoint) < 25
			) {
				// we are not dragging
				canvas.off("mouse:move", handleMouseMove);
				fLine.set("x2", fLine.x1);
				fLine.set("y2", fLine.y1);
				canvas.requestRenderAll();
				return;
			}

			fLine.set("x2", currentPoint.x);
			fLine.set("y2", currentPoint.y);

			// save line to ecogarden objects
			dispatch(addObject(toEcogardenObject(fLine)));
			// setLine(undefined);
			fLine = undefined;

			canvas._objects.sort(sortCanvasObjects);
			canvas.requestRenderAll();

			canvas.off("mouse:move", handleMouseMove);
		};

		// handle interaction to create a line
		// canvas.on("mouse:dblclick", handleMouseDoubleClick);
		canvas.on("mouse:down", handleMouseDown);
		canvas.on("mouse:up", handleMouseUp);
		return function cleanup() {
			//
			// canvas.off("mouse:dblclick", handleMouseDoubleClick);
			canvas.off("mouse:down", handleMouseDown);
			canvas.off("mouse:up", handleMouseUp);
			canvas.off("mouse:move", handleMouseMove);

			// if we have a line remaining, remove it before unloading
			if (fLine) {
				canvas.remove(fLine);
			}
		};
	}, [canvas, dispatch, layers, color, size]);

	return (
		<Box
			sx={{
				pointerEvents: "all",
				width: ["100vw", "22em"],
				maxWidth: ["calc(var(--vh, 1vh) * 100)", "75vw"],
			}}
		>
			{showSizes && (
				<StrokeSizes
					size={size}
					setSize={setSize}
					onSelected={() => {
						setShowSizes(false);
					}}
				/>
			)}
			<Grid
				sx={{
					backgroundColor: "accent-bg",
					px: [3, 2],
					py: 2,
					zIndex: 2,
					gridTemplateColumns: "44px 1fr auto",
					alignItems: "center",
					justifyItems: "center",
					gridGap: 4,
					borderRadius: "panelBottom",
					animation: `${slideUp} 96ms ease-in-out`,
					fontSize: [0, 1, 2],
				}}
			>
				<Label>
					<Input
						sx={{ padding: 0, height: [33, 44] }}
						type="color"
						name="color"
						value={color}
						onChange={(e) => setColor(e.target.value)}
					/>
				</Label>
				<Box>
					<Button
						variant="default"
						onClick={(e) => {
							setShowSizes((v) => (v ? false : true));
							(e.target as HTMLButtonElement).blur();
						}}
					>
						<LineIcon
							width="20"
							height="20"
							strokeWidth={sizeToNumber(size) / 2}
						/>{" "}
						{capitalize(size)}
					</Button>
				</Box>
				<Flex sx={{ justifyContent: "flex-end" }}>
					<Button
						variant="default"
						onClick={() => {
							onFinish();
						}}
					>
						<IconBlock icon={Check} /> Finish
					</Button>
				</Flex>
			</Grid>
		</Box>
	);
};

export default LineShape;
