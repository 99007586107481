/* eslint-disable max-lines-per-function */
import { useHotkeys } from "react-hotkeys-hook";
import { Grid, Label, Radio } from "theme-ui";
import slideUp from "../../lib/animations/slideup";
import LineIcon from "../../../svg/objects/line.svg";
import { sizeToNumber } from "../../lib/path";

type Sizes = "small" | "medium" | "large";

const sizes: readonly Sizes[] = ["small", "medium", "large"];

const StrokeSizes: React.FunctionComponent<{
  readonly size: "small" | "medium" | "large";
  readonly setSize: React.Dispatch<React.SetStateAction<Sizes>>;
  readonly onSelected?: () => void;
}> = ({ size, setSize, onSelected }) => {
  useHotkeys("left", () => {
    const index = sizes.indexOf(size);
    index === 0 ? setSize(sizes[sizes.length - 1]) : setSize(sizes[index - 1]);
  });
  useHotkeys("right", () => {
    const index = sizes.indexOf(size);
    index === sizes.length - 1 ? setSize(sizes[0]) : setSize(sizes[index + 1]);
  });
  useHotkeys("enter", () => {
    onSelected && onSelected();
  });
  return (
    <Grid
      sx={{
        backgroundColor: "background",
        fontSize: 0,
        gridAutoFlow: "column",
        gridGap: 1,
        animation: `${slideUp} 96ms ease-in-out`,
        height: 44,
      }}
    >
      <Label
        sx={{
          backgroundColor: size === "small" ? "accent-bg" : "transparent",
          "&:hover": { cursor: "pointer", backgroundColor: "accent-bg" },
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
          gap: 2,
        }}
      >
        <Radio
          type="radio"
          name="size"
          checked={size === "small"}
          onChange={() => {
            setSize("small");
            onSelected && onSelected();
          }}
          value="small"
        />
        <LineIcon
          width="18"
          height="18"
          strokeWidth={sizeToNumber("small") / 2}
        />{" "}
        Small
      </Label>
      <Label
        sx={{
          "&:hover": { cursor: "pointer", backgroundColor: "accent-bg" },
          backgroundColor: size === "medium" ? "accent-bg" : "transparent",
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
          gap: 2,
        }}
      >
        <Radio
          type="radio"
          name="size"
          checked={size === "medium"}
          onChange={() => {
            setSize("medium");
            onSelected && onSelected();
          }}
          value="medium"
        />
        <LineIcon
          width="18"
          height="18"
          strokeWidth={sizeToNumber("medium") / 2}
        />{" "}
        Medium
      </Label>

      <Label
        sx={{
          "&:hover": { cursor: "pointer", backgroundColor: "accent-bg" },
          backgroundColor: size === "large" ? "accent-bg" : "transparent",
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
          gap: 2,
        }}
      >
        <Radio
          type="radio"
          name="size"
          checked={size === "large"}
          onChange={() => {
            setSize("large");
            onSelected && onSelected();
          }}
          value="large"
        />
        <LineIcon
          width="18"
          height="18"
          strokeWidth={sizeToNumber("large") / 2}
        />{" "}
        Large
      </Label>
    </Grid>
  );
};

export default StrokeSizes;
